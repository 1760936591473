import {
  ChartBarIcon,
  ClockIcon,
  CogIcon,
  InformationCircleIcon,
  PlusCircleIcon,
  UserIcon,
} from '@heroicons/react/outline'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import { useUser } from '../../context/UserProvider'

type Props = {
  setIsInfoModalOpen: (value: boolean) => void
  setIsStatsModalOpen: (value: boolean) => void
  setIsDatePickerModalOpen: (value: boolean) => void
  setIsSettingsModalOpen: (value: boolean) => void
}

export const Navbar = ({
  setIsInfoModalOpen,
  setIsStatsModalOpen,
  setIsSettingsModalOpen,
}: Props) => {
  const navigate = useNavigate()
  const { userDetails } = useUser()

  const handleHome = () => {
    const currentURL = window.location.href
    if (currentURL.includes('game')) {
      window.location.href = '/'
      return
    } else {
      navigate('/')
    }
  }

  return (
    <div className="navbar py-1 md:mb-0">
      <div className="navbar-content flex justify-between px-5 short:h-auto">
        <div className="flex">
          <Link to={'/account'}>
            {userDetails.loggedIn && userDetails.isPhotoValid ? (
              <img
                src={userDetails.photoURL}
                alt="User"
                className="h-5 w-5 cursor-pointer rounded-full md:h-6 md:w-6"
              />
            ) : (
              <UserIcon className="h-5 w-5 cursor-pointer dark:stroke-white md:h-6 md:w-6" />
            )}
          </Link>

          <Link to={'/about'}>
            <InformationCircleIcon className="ml-3 h-5 w-5 cursor-pointer dark:stroke-white md:h-6 md:w-6" />
          </Link>
          <Link to={'/create'}>
            <PlusCircleIcon className="ml-3 h-5 w-5 cursor-pointer dark:stroke-white md:h-6 md:w-6" />
          </Link>
        </div>
        <div className="flex flex-grow justify-center">
          <div className={`product-sans text-center text-3xl md:text-4xl`}>
            <div className="relative flex items-end">
              <h1
                onClick={handleHome}
                className="product-sans-bold flex cursor-pointer font-bold"
              >
                <img
                src="/images/searchle.svg"
                alt={'Searchle Logo'}
                className="mr-2 w-5 md:w-8"
              />
                <span className="text-[#4285F4]">S</span>
                <span className="text-[#DB4437]">e</span>
                <span className="text-[#F4B400]">a</span>
                <span className="text-[#4285F4]">r</span>
                <span className="text-[#0F9D58]">c</span>
                <span className="text-[#DB4437]">h</span>
                <span className="text-[#4285F4]">l</span>
                <span className="text-[#0F9D58]">e</span>
              </h1>
            </div>
          </div>
        </div>
        <div className="right-icons flex">
          <Link to={'/archive'}>
            <ClockIcon className="mr-3 h-5 w-5 cursor-pointer dark:stroke-white md:h-6 md:w-6" />
          </Link>
          <ChartBarIcon
            className="mr-3 h-5 w-5 cursor-pointer dark:stroke-white md:h-6 md:w-6"
            onClick={() => setIsStatsModalOpen(true)}
          />
          <CogIcon
            className="h-5 w-5 cursor-pointer dark:stroke-white md:h-6 md:w-6"
            onClick={() => setIsSettingsModalOpen(true)}
          />
        </div>
      </div>
      <hr></hr>
    </div>
  )
}
